import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import {
  LayoutSl,
  SEO2,
  LWrap2,
  RecruitNews,
  Vertical,
  ContainerCrumbs,
} from "../../../components/_index";
import { recruitArea } from "../../../utils/recruit";
import "../../../assets/_sass/page/recruit/common.scss";
// markup

const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: "お知らせ",
    content: "Information",
    cla_left: "cla_left",
    cla_right: "cla_right",
 
  };

  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "お知らせ",
      src: "/recruit/news/",
    },
  ];
  return (
    <LayoutSl isKv={false} iscolor={false}>
      <SEO2 title={frontmatter?.title} description={frontmatter?.description} isTop={false}/>
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="new_main">
            <ContainerCrumbs data={crumbsData} />
            <div className="container_main container_main_1280">
              <Vertical data={verticalData}   />
              <RecruitNews />
            </div>
          </div>
        </LWrap2>
      </section>
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
